<template>
  <v-select
    :class="{
      's-text-field': true,
      'has-error': hasError,
    }"
    v-bind="$attrs"
    v-model="inputValue"
    v-on="$listeners"
    ref="input"
  >
    <template v-slot:prepend-item>
      <slot name="prepend-item" />
    </template>
    <slot />
  </v-select>
</template>

<script>
export default {
  name: 'SSelect',
  inheritAttrs: false,
  props: ['value'],
  data() {
    return {
      hasError: false,
      eventNames: ['focus', 'blur', 'change'],
    }
  },
  computed: {
    inputValue: {
      get() {
        return this.value
      },
      set(val) {
        this.$emit('input', val)
      },
    },
  },
  methods: {
    setHasError() {
      this.hasError = this.$refs.input.$el.classList.contains('error--text')
    },
  },
  mounted: function () {
    const inputEl = this.$refs.input.$el.querySelector('input')

    this.eventNames.map((eventName) =>
      inputEl.addEventListener(eventName, this.setHasError)
    )
  },
  beforeUnmount: function () {
    const inputEl = this.$refs.input.$el.querySelector('input')

    this.eventNames.map((eventName) =>
      inputEl.removeEventListener(eventName, this.setHasError)
    )
  },
}
</script>

<style lang="scss" scoped>
@import '@/assets/styles/_variables.scss';

::v-deep .v-input__slot {
  background: transparent !important;

  border: $borderline;
  border-radius: $default-radius;

  min-height: $input-height;
  height: $input-height;
}

::v-deep .v-input__slot:hover {
  border-color: var(--v-accent-base);
}

::v-deep .v-input__slot::after,
::v-deep .v-input__slot::before {
  display: none !important;
}

::v-deep .v-label {
  font-weight: $font-medium;
  font-size: $text-md !important;
  transform: translateY(-5px);
}

::v-deep .v-label--active {
  transform: translateY(-140%) scale(0.9);
  background: white;
}

::v-deep input {
  height: 32px;
  margin-top: 10px !important;

  font-weight: $font-medium;
  font-size: $text-md !important;
}

::v-deep .v-input__append-inner {
  margin-top: 10px;
}

.has-error ::v-deep .v-input__slot {
  border-color: var(--v-error-base) !important;
}

::v-deep {
  .v-select__selections {
    transform: translateY(-10px);
  }

  .v-select__selection {
    font-weight: $font-medium;
  }
}
</style>
